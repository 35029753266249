// DEV-NOTE: These are queries which is NOT a part of redux-thunk service pattern
// Notice: using await/async will not simplify. You want to use loading indicator. You do not want to await code.
// Naming Convention: Api-calls are post-fixed Async to make it clear that data is not axcessable on execution.

import { useState } from 'react';

import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import {
  BacktestParams,
  BacktestResult,
  Contract,
  ContractMarketData,
  MarketDataParams,
  OptionChainParams,
  OptionContract,
  OrderState,
  PlaceOrderParams,
  PlaceOrderReport,
  ScheduleInfo
} from '../types/entities';
import { OrderStatusType } from '../types/enums';

import {
  backtestPosition,
  checkBrokerConnection,
  checkOrderMargin,
  fetchMarketData,
  fetchOptionChain,
  isScheduleRunning,
  placeOrder,
  runOrderAutomationTask,
  toggleScheduleRunning
} from './api';

const showToastError = (e: AxiosError) => {
  const error = e as AxiosError<{ message: string }>;
  const msg = error.response?.data.message || error.message;
  toast.error(msg);
};

export const useBrokerConnection = (): {
  checkBrokerConnectionAsync: () => void;
  loading: boolean;
  connected: boolean;
  error: string | undefined;
} => {
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const checkBrokerConnectionAsync = () => {
    setLoading(true);
    checkBrokerConnection()
      .then((response) => {
        if (!!response.data && response.data === 'success') {
          setConnected(true);
          setError(undefined);
        } else {
          setConnected(false);
          const hasCustomErrorMsg = response?.data && response.data !== 'success' && response.data !== 'failed';
          if (hasCustomErrorMsg) {
            setError(response?.data);
          }
        }
      })
      .catch((e) => showToastError(e))
      .finally(() => setLoading(false));
  };
  return { checkBrokerConnectionAsync, loading, connected, error };
};

export const useMarketData = (): {
  fetchMarketDataAsync: (contract: Contract, pricesOnly?: boolean, noIndicators?: boolean) => void;
  loading: boolean;
  marketData: ContractMarketData | undefined;
} => {
  const [loading, setLoading] = useState(false);
  const [marketData, setMarketData] = useState<ContractMarketData | undefined>();

  const fetchMarketDataAsync = (contract: Contract, pricesOnly = true, noIndicators = true) => {
    const params: MarketDataParams = {
      conId: contract.conId,
      symbol: contract.symbol,
      localSymbol: contract.localSymbol,
      exchange: contract.exchange,
      currency: 'USD',
      secType: contract.secType,
      right: contract.right,
      strike: contract.strike,
      lastTradeDateOrContractMonth: contract.lastTradeDateOrContractMonth,
      multiplier: contract.multiplier,
      noIndicators,
      pricesOnly
    };

    setLoading(true);
    fetchMarketData(params)
      .then((response) => setMarketData(response.data))
      .catch((e: AxiosError) => showToastError(e))
      .finally(() => setLoading(false));
  };
  return { fetchMarketDataAsync, loading, marketData };
};

export const usePlaceOrder = (): {
  placeOrderAsync: (args: PlaceOrderParams) => void;
  loading: boolean;
  placeOrderReport: PlaceOrderReport | undefined;
} => {
  const [loading, setLoading] = useState(false);
  const [placeOrderReport, setPlaceOrderReport] = useState<PlaceOrderReport | undefined>();

  const placeOrderAsync = (args: PlaceOrderParams) => {
    setLoading(true);
    placeOrder(args)
      .then((response) => {
        const report = response.data;
        if (report.status !== OrderStatusType.Cancelled && report.status !== OrderStatusType.ApiCancelled) {
          toast.success(`Order submitted with status: ${OrderStatusType[report.status]}`);
        } else {
          toast.warning(`Order submitted with status: ${OrderStatusType[report.status]}`);
        }
        setPlaceOrderReport(response.data);
      })
      .catch((error) => showToastError(error))
      .finally(() => setLoading(false));
  };

  return {
    placeOrderAsync,
    loading,
    placeOrderReport
  };
};

export const useCheckMargin = () => {
  const [loading, setLoading] = useState(false);
  const [orderState, setOrderState] = useState<OrderState | undefined>();

  const checkMarginAsync = (args: PlaceOrderParams) => {
    setLoading(true);
    checkOrderMargin(args)
      .then((response) => {
        setOrderState(response.data);
      })
      .catch((error) => showToastError(error))
      .finally(() => setLoading(false));
  };

  return {
    checkMarginAsync,
    loading,
    orderState
  };
};

export const useOptionChain = (): {
  fetchOptionChainAsync: (args: OptionChainParams) => void;
  loading: boolean;
  error: string | undefined;
  options: OptionContract[];
} => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [options, setOptions] = useState<OptionContract[]>([]);

  const fetchOptionChainAsync = (args: OptionChainParams) => {
    setLoading(true);
    setError(undefined);
    fetchOptionChain(args)
      .then((response) => setOptions(response.data))
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        setError(msg);
      })
      .finally(() => setLoading(false));
  };

  return {
    fetchOptionChainAsync,
    loading,
    error,
    options
  };
};

export const useServerSchedule = (): {
  checkScheduleInfoAsync: () => void;
  toggleScheduleRunAsync: () => void;
  loading: boolean;
  error: string | undefined;
  scheduleInfo: ScheduleInfo;
} => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [scheduleInfo, setScheduleInfo] = useState<ScheduleInfo>({ repeats: 0, state: '' });

  const checkScheduleInfoAsync = () => {
    setLoading(true);
    setError(undefined);
    isScheduleRunning()
      .then((response) => {
        const schedules = response.data;
        setScheduleInfo(schedules[0]);
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        setError(msg);
      })
      .finally(() => setLoading(false));
  };

  const toggleScheduleRunAsync = () => {
    setLoading(true);
    toggleScheduleRunning()
      .then((response) => {
        const newState = response.data;
        const copy = {
          ...scheduleInfo,
          state: newState
        };
        setScheduleInfo(copy);
      })
      .catch((error) => {
        showToastError(error);
      })
      .finally(() => setLoading(false));
  };

  return {
    checkScheduleInfoAsync,
    toggleScheduleRunAsync,
    loading,
    error,
    scheduleInfo
  };
};

export const useBacktestPosition = (): {
  backtestPositionAsync: (params: BacktestParams) => void;
  clearBacktestResult: () => void;
  loading: boolean;
  backtestResult: BacktestResult | undefined;
} => {
  const [loading, setLoading] = useState(false);
  const [backtestResult, setBacktestResult] = useState<BacktestResult | undefined>();

  const clearBacktestResult = () => setBacktestResult(undefined);

  const backtestPositionAsync = (params: BacktestParams) => {
    setLoading(true);
    backtestPosition(params)
      .then((response) => setBacktestResult(response.data))
      .catch((error) => showToastError(error))
      .finally(() => setLoading(false));
  };

  return {
    backtestPositionAsync,
    clearBacktestResult,
    loading,
    backtestResult
  };
};

export const useRunOrderAutomationTask = (): { testRunAveragingTaskAsync: (conId: number) => void; loading: boolean } => {
  const [loading, setLoading] = useState(false);

  const testRunAveragingTaskAsync = (conId: number) => {
    runOrderAutomationTask(conId)
      .catch((error) => showToastError(error))
      .finally(() => setLoading(false));
  };

  return {
    testRunAveragingTaskAsync,
    loading
  };
};
