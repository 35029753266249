import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardState, initialDashboardState } from './state';

const dashboaredSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    reset: () => initialDashboardState,
    loading: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    loaded: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loaded = action.payload;
    },
    success: (state: DashboardState) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.initialLoaded = true;
    },
    fail: (state: DashboardState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.initialLoaded = true;
    }
  }
});

const { actions, reducer } = dashboaredSlice;
export const { reset, loading, loaded, success, fail } = actions;
export default reducer;
