import { lazy, Suspense } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

//import Account from './pages/AccountPage';
const Account = lazy(() => import('./pages/AccountPage'));
// import Dashboard from './pages/Dashboard';
const Dashboard = lazy(() => import('./pages/Dashboard'));
// import LoginPage from './pages/LoginPage';
const LoginPage = lazy(() => import('./pages/LoginPage'));
// import OrderAutomationPage from './pages/OrderAutomationPage';
const OrderAutomationPage = lazy(() => import('./pages/OrderAutomationPage'));
// import PlaceOrderPage from './pages/PlaceOrderPage';
const PlaceOrderPage = lazy(() => import('./pages/PlaceOrderPage'));
// import PositionHistoryPage from './pages/PositionHistoryPage';
const PositionHistoryPage = lazy(() => import('./pages/PositionHistoryPage'));
// import SecurityListPage from './pages/SecurityListPage';
const SecurityListPage = lazy(() => import('./pages/SecurityListPage'));
// import SecurityPage from './pages/SecurityPage';
const SecurityPage = lazy(() => import('./pages/SecurityPage'));
// import TestDatePage from './pages/TestDatePage';
const TestDatePage = lazy(() => import('./pages/TestDatePage'));
// import WatchlistPage from './pages/WatchlistPage';
const WatchlistPage = lazy(() => import('./pages/WatchlistPage'));

export const getRoutes = (authorized: boolean) => {
  if (!authorized) {
    return (
      <Suspense fallback={<div> Loading...</div>}>
        <Routes>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="*" element={<Navigate to="login" replace />} />
        </Routes>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<div> Loading...</div>}>
      <Routes>
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/place-order/:conId" element={<PlaceOrderPage />}></Route>
        <Route path="/order-automation/:conId" element={<OrderAutomationPage />}></Route>
        {/* <Route path="/order" element={<OrderPage />}></Route> */}
        {/* <Route path="/order-summary/:symbol" element={<OrderSummaryPage />}></Route> */}
        <Route path="/securities" element={<SecurityListPage />}></Route>
        <Route path="/watchlist" element={<WatchlistPage />}></Route>
        <Route path="/security/:symbol" element={<SecurityPage />}></Route>
        {/* <Route path="/back-office" element={<BackOfficePage />}></Route> */}
        <Route path="/account" element={<Account />}></Route>
        <Route path="/history" element={<PositionHistoryPage />}></Route>
        <Route path="/test-date" element={<TestDatePage />}></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};
