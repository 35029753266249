import { FunctionComponent, useEffect } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Footer from './components/panels/Footer';
import Header from './components/panels/Header';
import Spinner from './components/ui/Spinner';
import { getRoutes } from './routes';
import { useContinuousAuthValidation } from './store/auth/hooks';
import { validate } from './store/auth/service';
import { loadFoundationDataAsync } from './store/foundation-data/service';
import { useAppDispatch, useAppSelector } from './store/hooks';

const App: FunctionComponent = () => {
  useContinuousAuthValidation();
  const dispatch = useAppDispatch();

  const { token, loaded: authLoaded } = useAppSelector((gs) => gs.authState);
  const isAuthenticated = authLoaded && !!token;
  useEffect(() => {
    if (!authLoaded) {
      dispatch(validate());
    }
  }, []);

  // load foundation data; data uses for multiple screens - securities, front-runners
  const { loading: foundationDataLoading, loaded } = useAppSelector((gs) => gs.foundationDataState);
  useEffect(() => {
    if (isAuthenticated && !loaded) {
      dispatch(loadFoundationDataAsync());
    }
  }, [isAuthenticated]);

  const loading = !authLoaded || foundationDataLoading;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isAuthenticated && <Header />}
      <Spinner loading={loading} />
      {authLoaded && getRoutes(isAuthenticated)}
      {isAuthenticated && <Footer />}
    </LocalizationProvider>
  );
};

export default App;
