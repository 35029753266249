import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import HourglassTop from '@mui/icons-material/HourglassTop';
import OnlineIcon from '@mui/icons-material/PowerSettingsNew';
import { IconButton, Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useBrokerConnection } from '../../api/api-hooks';
import { INDICATOR_GREEN_COLOR, INDICATOR_NA_COLOR, INDICATOR_RED_COLOR, INDICATOR_YELLOW_COLOR } from '../../assets/colors';

const BrokerStatusMenuButton: FunctionComponent = () => {
  const { checkBrokerConnectionAsync, loading, connected, error } = useBrokerConnection();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    checkBrokerConnectionAsync();
  }, []);

  const statusIcon = useMemo(() => {
    if (loading) {
      return <HourglassTop sx={{ color: INDICATOR_NA_COLOR }} />;
    } else if (connected) {
      return <OnlineIcon sx={{ color: INDICATOR_GREEN_COLOR }} />;
    } else if (error) {
      return (
        <Tooltip title={error}>
          <OnlineIcon sx={{ color: INDICATOR_YELLOW_COLOR }} />
        </Tooltip>
      );
    } else {
      return <OnlineIcon sx={{ color: INDICATOR_RED_COLOR }} />;
    }
  }, [loading, connected]);

  const statusIconButton = (
    <IconButton
      color="primary"
      id="fade-button"
      aria-controls={open ? 'fade-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      {statusIcon}
    </IconButton>
  );

  const handleInfoClick = () => {
    checkBrokerConnectionAsync();
    handleClose();
  };

  return (
    <div>
      {statusIconButton}
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleInfoClick}>Check Broker Status</MenuItem>
      </Menu>
    </div>
  );
};

export default BrokerStatusMenuButton;
