import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractDetails, OptionRight, TradingClass, TrendLine } from '../../types/entities';
import { SecurityCategory, SecuritySector } from '../../types/enums';

import { UserSettingsState, initialState } from './state';

const userSlice = createSlice({
  name: 'user-settings',
  initialState,
  reducers: {
    setUseFrozen: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.useFrozen = action.payload;
    },
    setUseSnapshot: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.useSnapshot = action.payload;
    },
    setNewsPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.newsPanelCollapsed = action.payload;
    },
    setIndicatorPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.indicatorPanelCollapsed = action.payload;
    },
    setHourGraphCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.hourGraphCollapsed = action.payload;
    },
    setDailyGraphCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.dailyGraphCollapsed = action.payload;
    },
    setSelectedCategory: (state: UserSettingsState, action: PayloadAction<SecurityCategory>) => {
      state.selectedSecurityCategory = action.payload;
    },
    setSelectedContractDetails: (state: UserSettingsState, action: PayloadAction<ContractDetails | undefined>) => {
      state.selectedContractDetails = action.payload;
    },
    setSecurityPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.securityPanelCollapsed = action.payload;
    },
    setSelectedSecurityId: (state: UserSettingsState, action: PayloadAction<string | undefined>) => {
      state.selectedSecurityId = action.payload;
    },
    setSelectedSecuritySector: (state: UserSettingsState, action: PayloadAction<SecuritySector | undefined>) => {
      state.selectedSecuritySector = action.payload;
    },
    setTrendLinesCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.trendLinesCollapsed = action.payload;
    },
    setShortListCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.shortListCollapsed = action.payload;
    },
    setScurityPresetsCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.securityPresetsCollapsed = action.payload;
    },
    setQuickNotesCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.quickNotesCollapsed = action.payload;
    },
    setSelectedContractSection: (state: UserSettingsState, action: PayloadAction<'all-contracts' | 'front-runners'>) => {
      state.selectedContractSection = action.payload;
    },
    setSelectedBackOfficeTab: (state: UserSettingsState, action: PayloadAction<'securities' | 'portfolios' | 'fundamentals'>) => {
      state.selectedBackOfficeTab = action.payload;
    },
    setSelectedSecurityTab: (state: UserSettingsState, action: PayloadAction<string>) => {
      state.selectedSecurityTab = action.payload;
    },
    setSelectedWatchlistTab: (state: UserSettingsState, action: PayloadAction<string>) => {
      state.selectedWatchlistTab = action.payload;
    },
    setSelectedTradingClass: (
      state: UserSettingsState,
      action: PayloadAction<{ symbol: string; tradingClass: TradingClass | undefined }>
    ) => {
      state.optionSettings[action.payload.symbol] = {
        ...state.optionSettings[action.payload.symbol],
        selectedTradingClass: action.payload.tradingClass
      };
    },
    setSelectedOptionRight: (state: UserSettingsState, action: PayloadAction<{ symbol: string; right: OptionRight }>) => {
      state.optionSettings[action.payload.symbol] = {
        ...state.optionSettings[action.payload.symbol],
        selectedRight: action.payload.right
      };
    },
    setSelectedTrendLine: (state: UserSettingsState, action: PayloadAction<TrendLine | undefined>) => {
      state.selectedTrendLine = action.payload;
    }
  }
});

const { actions, reducer } = userSlice;
export const {
  setUseFrozen,
  setUseSnapshot,
  setNewsPanelCollapsed,
  setIndicatorPanelCollapsed,
  setHourGraphCollapsed,
  setDailyGraphCollapsed,
  setSelectedCategory,
  setSelectedSecurityId,
  setSelectedContractDetails,
  setSecurityPanelCollapsed,
  setTrendLinesCollapsed,
  setShortListCollapsed,
  setScurityPresetsCollapsed,
  setQuickNotesCollapsed,
  setSelectedContractSection,
  setSelectedBackOfficeTab,
  setSelectedSecurityTab,
  setSelectedWatchlistTab,
  setSelectedTradingClass,
  setSelectedOptionRight,
  setSelectedTrendLine,
  setSelectedSecuritySector
} = actions;
export default reducer;
