import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { loadFoundationData } from '../../api';
import { Logger } from '../../utils/Logger';
import { success as frontRunnerSuccess } from '../front-runner/reducer';
import { success as securitySuccess } from '../security/reducer';
import { success as trendLineSuccess } from '../trend-line/reducer';

import { loading, fail } from './reducer';

export const loadFoundationDataAsync = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    loadFoundationData()
      .then((response) => {
        const foundationData = response.data;
        Logger.log(foundationData);
        dispatch(securitySuccess(foundationData.securities));
        dispatch(trendLineSuccess(foundationData.trendLines));
        dispatch(frontRunnerSuccess(foundationData.frontRunners));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};
