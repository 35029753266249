import { FrontRunner } from '../../types/entities';

export interface FrontRunnerState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  frontRunners: FrontRunner[];
}

export const initialState: FrontRunnerState = {
  loading: false,
  loaded: false,
  error: undefined,
  frontRunners: []
};
