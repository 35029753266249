import { ContractDetails, OptionRight, TradingClass, TrendLine } from '../../types/entities';
import { SecurityCategory, SecuritySector } from '../../types/enums';

export interface UserSettingsState {
  useFrozen: boolean;
  useSnapshot: boolean;
  newsPanelCollapsed: boolean;
  indicatorPanelCollapsed: boolean;
  hourGraphCollapsed: boolean;
  dailyGraphCollapsed: boolean;
  selectedSecurityCategory: SecurityCategory;
  selectedSecurityId: string | undefined;
  selectedSecuritySector: SecuritySector | undefined;
  selectedContractDetails?: ContractDetails | undefined;
  securityPanelCollapsed: boolean;
  trendLinesCollapsed: boolean;
  shortListCollapsed: boolean;
  securityPresetsCollapsed: boolean;
  quickNotesCollapsed: boolean;
  selectedContractSection: 'all-contracts' | 'front-runners';
  selectedBackOfficeTab: 'securities' | 'portfolios' | 'fundamentals';
  selectedSecurityTab: string;
  selectedWatchlistTab: string;
  selectedTrendLine?: TrendLine | undefined;
  optionSettings: {
    [symbol: string]: {
      selectedTradingClass: TradingClass | undefined;
      selectedRight: OptionRight;
    };
  };
}

export const initialState: UserSettingsState = {
  useFrozen: false,
  useSnapshot: false,
  newsPanelCollapsed: false,
  indicatorPanelCollapsed: false,
  hourGraphCollapsed: false,
  dailyGraphCollapsed: false,
  selectedSecurityCategory: SecurityCategory.Shortlist,
  selectedSecurityId: undefined,
  selectedSecuritySector: undefined,
  securityPanelCollapsed: true,
  trendLinesCollapsed: false,
  shortListCollapsed: false,
  securityPresetsCollapsed: true,
  quickNotesCollapsed: false,
  selectedContractSection: 'all-contracts',
  selectedBackOfficeTab: 'securities',
  selectedSecurityTab: 'general',
  selectedWatchlistTab: 'shortlist',
  optionSettings: {}
};
