import { FunctionComponent, useEffect, useState } from 'react';

import HourglassTop from '@mui/icons-material/HourglassTop';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { IconButton } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useServerSchedule } from '../../api/api-hooks';
import { INDICATOR_GREEN_COLOR, INDICATOR_NA_COLOR, INDICATOR_RED_COLOR } from '../../assets/colors';

const ScheduleStatusMenuButton: FunctionComponent = () => {
  const { checkScheduleInfoAsync, toggleScheduleRunAsync, loading, error, scheduleInfo } = useServerSchedule();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    checkScheduleInfoAsync();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInfoClick = () => {
    checkScheduleInfoAsync();
    handleClose();
  };

  const handleToggleClick = () => {
    toggleScheduleRunAsync();
    handleClose();
  };

  let scheduleIcon: JSX.Element;
  if (loading) {
    scheduleIcon = <HourglassTop sx={{ color: '#ccc' }} />;
  } else if (error) {
    scheduleIcon = <TimelapseIcon sx={{ fontSize: 23, color: INDICATOR_RED_COLOR }} />;
  } else if (scheduleInfo.state === 'RUNNING') {
    scheduleIcon = <TimelapseIcon sx={{ fontSize: 23, color: INDICATOR_GREEN_COLOR }} />;
  } else {
    scheduleIcon = <TimelapseIcon sx={{ fontSize: 23, color: INDICATOR_NA_COLOR }} />;
  }

  const scheduleIconButton = (
    <IconButton
      color="primary"
      id="fade-button"
      aria-controls={open ? 'fade-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      {scheduleIcon}
    </IconButton>
  );

  return (
    <div>
      {scheduleIconButton}
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleInfoClick}>Check Schedule Status</MenuItem>
        <MenuItem onClick={handleToggleClick}>Toggle Run</MenuItem>
      </Menu>
    </div>
  );
};

export default ScheduleStatusMenuButton;
