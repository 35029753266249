import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FrontRunner } from '../../types/entities';

import { FrontRunnerState, initialState } from './state';

const frontRunnerSlice = createSlice({
  name: 'front-runner',
  initialState,
  reducers: {
    reset: () => initialState,
    loading: (state: FrontRunnerState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: FrontRunnerState, action: PayloadAction<FrontRunner[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.frontRunners = action.payload;
    },
    fail: (state: FrontRunnerState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.frontRunners = [];
    },
    remove: (state: FrontRunnerState, action: PayloadAction<FrontRunner>) => {
      const conId = action.payload.details.contract.conId;
      const entry = state.frontRunners.find((x) => x.details.contract.conId === conId);
      const index = entry ? state.frontRunners.indexOf(entry) : -1;
      if (index !== -1) {
        state.frontRunners.splice(index, 1);
      }
      state.loading = false;
      state.loaded = true;
    },
    set: (state: FrontRunnerState, action: PayloadAction<FrontRunner>) => {
      const favs = state.frontRunners.filter((x) => x.details.contract.symbol !== action.payload.details.contract.symbol);
      state.frontRunners = [...favs, action.payload];
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = frontRunnerSlice;
export const { reset, loading, success, fail, set, remove } = actions;
export default reducer;
